import React from "react";

// React
import { useState, useEffect } from "react";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { Controller, Scene } from "react-scrollmagic";
import { Link } from "gatsby";

// Heroicons
import {
  ArrowNarrowDownIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/outline";

// SVGs
import AritaeLogo from "../../images/logo/logo.inline.svg";
import AritaeLogoLetters from "../../images/logo/logo-letters.inline.svg";

// Shared Components
import Footer from "../../components/shared/footer/Footer";
import Header from "../../components/shared/header/Header";
import TextModal from "../../components/shared/modal/Modal";
import LoadingButton from "../../components/shared/loading-button/LoadingButton";
import Seo from "../../components/seo";
import CoachesCarousel from "../../components/shared/coaches-carousel/CoachesCarousel";

// Service
import { contactSubmission } from "../../services/contactAPI";

export default function Coaches() {
  const [largeScreen, setLargeScreen] = useState(true);
  const [durationScrollMagic, setDurationScrollMagic] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
      setDurationScrollMagic(0);
    } else {
      setLargeScreen(true);
      setDurationScrollMagic(500);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images

  const HeaderImg = cld
    .image("v1646250365/Aritae/Coaches/coahces-header_icos8v.jpg")
    .quality("auto");
  const HeaderMobile = cld
    .image("v1646250366/Aritae/Coaches/coaches-header-mobile_lua716.jpg")
    .quality("auto");
  const Leaders = cld
    .image("v1645456983/Aritae/Coaches/coaches-leaders_thk4e2.jpg")
    .quality("auto");

  const SignUpBanner = cld
    .image("v1645456983/Aritae/Coaches/coaches-sign-up-banner_grj86h.jpg")
    .quality("auto");
  const SignUpBannerMobile = cld
    .image("v1646074249/Aritae/Coaches/coaches-sign-up-banner_bdqnlh.jpg")
    .quality("auto");

  //email sent from contact form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const email = event.target.email.value;

    let postBody = JSON.stringify({
      Email: email,
      PageName: "Coaches",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <div id="root">
      <Header />
      <Seo
        title="Coaches"
        description="Learn about ARITAE coaches, how they are selected and how to become a coach."
      />
      <div className="relative bg-gray-100">
        <Controller>
          {/* Modal */}
          <TextModal
            isOpen={isOpen}
            close={toggleModalOpen}
            icon={icon}
            ariaHideApp={false}
            description={description}
          />
          {/* START HERO SECTION */}
          <div className="h-screen relative">
            <div className="absolute inset-0">
              <div className="absolute flex-1 top-0 inset-0">
                <div className={"absolute inset-0 opacity-50 bg-stone-500"} />

                <AdvancedImage
                  plugins={[responsive({ steps: 200 })]}
                  cldImg={largeScreen ? HeaderImg : HeaderMobile}
                  alt="coach showing young woman a play on a clipboard in a green field"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="relative">
              <div className="flex flex-col h-screen items-center justify-center">
                <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
                  <span className="block text-white text-2xl pb-6">
                    COACHES
                  </span>
                </h1>
                <div
                  className={"flex flex-col items-center justify-center my-12"}
                >
                  <AritaeLogo
                    fill={"white"}
                    className={"h-32 w-auto mx-auto pb-2"}
                  />
                  <AritaeLogoLetters
                    fill={"white"}
                    className={"h-auto w-48 mx-auto"}
                  />
                </div>
                <p className={"text-white text-center font-bold text-4xl mt-4"}>
                  INSPIRE. LEAD. TRANSFORM.
                </p>
                <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
                  <ArrowNarrowDownIcon className={"h-7 text-white"} />
                </div>
              </div>
            </div>
          </div>
          {/* END HERO SECTION */}

          {/* START WHAT IT TAKES */}
          <div className="mx-auto my-36 px-16 max-w-7xl">
            <div className="text-center space-y-10">
              <h1 className="text-4xl  text-slate-600">
                WHAT IT TAKES TO BE AN{" "}
                <span className="font-bold">ARITAE COACH</span>
              </h1>
              <p className="text-xl text-slate-600 leading-10">
                A sense of personal responsibility to those around you and a
                passion to guide and inspire young people to unleash their
                potential, while helping to make the world a better place for
                all. Coaches can be athletes, teachers, counselors, social
                workers, athletic coaches, or other professionals who would like
                to add coaching young people to their existing occupation or
                make it a full-time profession.
              </p>
            </div>
          </div>
          {/* END WHAT IT TAKES */}

          {/* START COACHES ARE LEADERS */}
          <div className="relative py-36">
            <div className="absolute flex-1 top-0 inset-0">
              <AdvancedImage
                plugins={[responsive({ steps: 200 })]}
                cldImg={Leaders}
                alt="coach with group of people in huddle"
                className="h-full w-full object-cover"
              />
            </div>
            <div className={"absolute flex inset-0 bg-overlay opacity-50"} />
            <div className="relative max-w-7xl px-16 mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                  <h1 className="text-4xl font-bold text-white text-left md:text-right">
                    ARITAE COACHES ARE LEADERS IN THEIR PROFESSIONS AND LOCAL
                    COMMUNITIES
                  </h1>
                </div>
                <div>
                  <p className="text-xl text-white leading-10">
                    ARITAE Coaches are leaders in their local communities and
                    beyond. They have realized their importance in guiding young
                    people toward achieving their goals, leading their lives
                    with purpose, and continuing to live inspired lives.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* END COACHES ARE LEADERS */}

          {/* START EACH ARITAE COACH */}
          <div
            className={
              "grid grid-cols-1 md:grid-cols-2 gap-10 px-6 md:px-16 max-w-7xl mx-auto pt-12 my-24"
            }
          >
            {/* First section */}
            <div className="bg-slate-600 p-10 space-y-5">
              <h1 className="text-white text-4xl">
                <span>ARITAE COACHES </span>
                <span className="font-extrabold">ARE</span>
              </h1>
              <p className="text-white text-xl pb-10">
                ARITAE coaches are dedicated to our stated purpose and agree to
                guide young people to unleash their potential in any areas of
                life where they want to achieve results. Inspiring others,
                leading by example, and making the effort each day is what makes
                an ARITAE Coach.
              </p>
            </div>

            {/* Second section */}
            <div
              className={
                "relative grid grid-rows-3 justify-center items-center"
              }
            >
              <div>
                <h1 className="text-left text-slate-600 text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl flex flex-row space-x-5">
                  <span className="text-6xl">
                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                  </span>
                  <span className="block">
                    EACH COACH IS CAREFULLY REVIEWED, EVALUATED AND SELECTED{" "}
                  </span>
                </h1>
              </div>
              <div>
                <h1 className="text-left text-slate-600 text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl flex flex-row space-x-5">
                  <span className="text-6xl">
                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                  </span>
                  <span className="block">
                    EACH COACH HAS COMPLETED THE ARITAE SELF-LEADERSHIP COACH
                    TRAINING PROGRAM.{" "}
                  </span>
                </h1>
              </div>
              <div>
                <h1 className="text-left text-slate-600 text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl flex flex-row space-x-5">
                  <span className="text-6xl">
                    <ArrowNarrowRightIcon className="h-6 inline pr-4" />
                  </span>
                  <span className="block">
                    EACH COACH IS COMMITTED TO YOUNG PEOPLE AND THEIR QUEST TO
                    UNLEASH THEIR POTENTIAL
                  </span>
                </h1>
              </div>
            </div>
          </div>
          {/* END EACH ARITAE COACH */}

          {/* START OUR COACHES */}
          <div className="relative">
            <div className={"relative mt-24 md:py-24 max-w-7xl mx-auto"}>
              <div className={"pb-12"}>
                <div className={"flex justify-center items-center"}>
                  <Link to="/academy/coaches">
                    <div className="text-slate-600 hover:text-mustard hover:opacity-90 text-4xl text-center">
                      <h1 className="animate-bounce">
                        MEET THE{" "}
                        <span className="font-extrabold">ARITAE COACHES</span>
                      </h1>
                    </div>
                  </Link>
                </div>
              </div>
              {/* Carousel */}
              <div className="relative max-h-md">
                <CoachesCarousel />
              </div>
            </div>
          </div>
          {/* END OUR COACHES */}

          {/* START WHO CAN APPLY*/}
          <div className="bg-mustard bg-opacity-10  py-24">
            <div
              className={
                "grid grid-cols-1 sm:grid-cols-2 gap-10 px-16 max-w-7xl mx-auto"
              }
            >
              {/* First section */}
              <div className="bg-slate-600">
                <div className=" p-10  space-y-5">
                  <h1 className="text-white text-4xl">
                    WHO CAN <span className="font-extrabold">APPLY</span> TO BE
                    AN <span className="font-extrabold">ARITAE COACH</span>
                  </h1>
                  <div className="text-white text-sm md:text-lg lg:text-xl pb-10">
                    <div /> {/*This is a filler */}
                    <div className="flex flex-col col-span-2 justify-start">
                      <div className="flex flex-row items-center space-x-5">
                        <ArrowNarrowRightIcon className="h-10" />
                        <p>ATHLETIC COACHES</p>
                      </div>
                      <div className="flex flex-row items-center space-x-5">
                        <ArrowNarrowRightIcon className="h-10" />
                        <p>TEACHERS</p>
                      </div>
                      <div className="flex flex-row items-center space-x-5">
                        <ArrowNarrowRightIcon className="h-10" />
                        <p>ATHLETES/STUDENTS</p>
                      </div>
                      <div className="flex flex-row items-center space-x-5">
                        <ArrowNarrowRightIcon className="h-10" />
                        <p>COUNSELORS</p>
                      </div>
                      <div className="flex flex-row items-center space-x-5">
                        <ArrowNarrowRightIcon className="h-10" />
                        <p>PROFESSIONALS</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second section */}
              <div className={"relative flex justify-center items-center"}>
                <div>
                  <h1 className="text-left text-4xl font-extrabold tracking-tight">
                    <span className="block text-slate-600">
                      IF YOU DESIRE TO INSPIRE
                    </span>
                  </h1>
                  <br />
                  <p className="text-left font-serif text-slate-600 md:text-md lg:text-xl">
                    Coaches, teachers, students, counselors, professionals, or
                    people who have the experience and passion to inspire young
                    people to unleash their potential in all areas of their
                    lives. Utilize your natural talent, personal brand, and
                    professional expertise in partnership with our proven ARITAE
                    program and platform to connect and coach young people,
                    guiding them to live a life filled with love, happiness,
                    significance, and success.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* END WHO CAN APPLY */}

          {/* START OUR APPLICATION*/}
          <div
            className={
              "grid grid-cols-1 sm:grid-cols-2 gap-10 px-6 lg:px-16 max-w-7xl mx-auto my-24"
            }
          >
            {/* First section */}
            <Scene
              duration={durationScrollMagic}
              pin={{ pushFollowers: true }}
              triggerHook={0}
              offset={-20}
              enabled={largeScreen}
            >
              <div className="bg-slate-600 p-8 md:p-10 space-y-5">
                <h1 className="text-white text-4xl">
                  OUR <span className="font-extrabold">APPLICATION</span>
                </h1>
                <p className="text-white text-xl pb-10">
                  You will complete our online Coach Application and will be
                  updated as the status of your application is processed. Once
                  your application is approved, you must complete the ARITAE
                  Coach Training program.
                </p>
              </div>
            </Scene>

            {/* Second section */}
            <Scene duration={100} triggerHook={0}>
              {(progress) => (
                <div className={"relative"}>
                  <div
                    className={
                      "flex flex-col max-w-sm sm:ml-10 sm:mt-20 space-y-2 sm:space-y-40"
                    }
                  >
                    <div>
                      <h1 className="text-left text-slate-600 text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl flex flex-row space-x-5">
                        <span className="text-6xl">1</span>
                        <span className="block">
                          YOUR APPLICATION IS REVIEWED CAREFULLY TO ENSURE THAT
                          YOU ARE A GOOD FIT FOR ARITAE{" "}
                        </span>
                      </h1>
                    </div>

                    <div>
                      <h1 className="text-left text-slate-600 text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl flex flex-row space-x-5">
                        <span className="text-6xl">2</span>
                        <span className="block uppercase">
                          If your application is accepted you will be asked to
                          agree to a criminal background check.
                        </span>
                      </h1>
                    </div>

                    <div>
                      <h1 className="text-left text-slate-600 text-2xl font-extrabold tracking-tight sm:text-xl lg:text-2xl flex flex-row space-x-5">
                        <span className="text-6xl">3</span>
                        <span className="block uppercase">
                          Complete the ARITAE Self-leadership Academy Coach
                          Training Program{" "}
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              )}
            </Scene>
          </div>
          {/* END OUR APPLICATION */}

          {/* START Sign Up Form */}
          <div className={"flex h-screen justify-center items-center mt-24"}>
            <div className="absolute z-10 flex h-screen w-screen overflow-hidden">
              <div className={"absolute inset-0 opacity-75 bg-stone-500"} />
              <AdvancedImage
                plugins={[responsive({ steps: 200 })]}
                cldImg={largeScreen ? SignUpBanner : SignUpBannerMobile}
                alt="Collage of people doing sports, science, art, music"
                className="w-full h-full object-cover"
              />
            </div>

            <div className="relative z-20 mx-auto max-w-3xl text-center md:px-5">
              <div className="py-10">
                <h4 className="text-4xl font-extrabold text-white">
                  JOIN OTHER COACHES HELPING BRING INSPIRATION TO THE WORLD
                </h4>
              </div>

              <form
                className="space-y-6 row-auto py-5 p-4  max-w-3xl xs:m-12 md:m-0"
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                    <div className="mt-1 md:col-span-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email"
                        required
                        className="text-center md:text-left w-full px-5 py-3 text-gray-500 border-transparent placeholder-gray-500 bg-gray-100 font-sm md:font-md"
                      />
                    </div>

                    <div className="mt-1">
                      {loading ? (
                        <LoadingButton type={"email"} />
                      ) : (
                        <button
                          type="submit"
                          className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black hover:bg-gray-800"
                        >
                          SIGN UP
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* END Sign Up Form */}
        </Controller>
      </div>
      <Footer />
    </div>
  );
}
